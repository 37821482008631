import React, { useEffect } from "react";
import { option } from "../types/form";
import { ReactComponent as ArrowRightIcon } from "../svgs/arrow-right.svg";
import { map, without } from "lodash-es";

type DropdownCheckboxGroupProps = {
  label: string;
  name: string;
  options: option[];
  onChange?: (checked: boolean, value: option) => void;
  onCheckedChange?: (allChecked: any[]) => void;
  isOption?: boolean;
  defaultValue?: null | string | number;
};

type DarkCheckboxProps = {
  readOnly?: boolean;
  value: string | number;
  label: string;
  name: string;
  isChecked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

type DarkRadioProps = {
  readOnly?: boolean;
  value: string | number;
  label: string;
  name: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function DropdownCheckboxGroup({
  label,
  name,
  options,
  onChange,
  onCheckedChange,
  isOption = false,
  defaultValue = null,
}: DropdownCheckboxGroupProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [radioChecked, setRadioChecked] = React.useState(defaultValue);
  const [allChecked, setAllChecked] = React.useState<any[]>([]);
  useEffect(() => {
    if (onCheckedChange !== undefined) {
      onCheckedChange(allChecked);
    }
    // eslint-disable-next-line
  }, [allChecked]);
  return (
    <div className="relative">
      <div
        className="text-gray-600 flex items-center gap-4 cursor-pointer"
        onClick={() => {
          setIsOpen((prevState) => !prevState);
        }}
      >
        <div>{label}</div>
        <ArrowRightIcon
          className={`h-4 fill-current transform ${
            isOpen ? "-rotate-90" : "rotate-90"
          }`}
        />
      </div>
      <div
        className={`${!isOpen ? "hidden" : ""} absolute p-4 w-64 bg-white z-40`}
      >
        {!isOption && (
          <>
            {map(options, ({ label, value }, index) => (
              <DarkCheckbox
                key={index}
                label={label}
                value={value}
                name={name}
                onChange={async (e) => {
                  if (e.target.checked) {
                    setAllChecked((oldProps) => [...oldProps, value]);
                  } else {
                    setAllChecked((oldProps) => without(oldProps, value));
                  }
                  if (onChange !== undefined) {
                    onChange(e.target.checked, { label, value });
                  }
                }}
              />
            ))}
          </>
        )}

        {isOption && (
          <>
            {map(options, ({ label, value }, index) => (
              <DarkRadio
                key={index}
                label={label}
                value={value}
                name={name}
                checked={radioChecked === value}
                onChange={async (e: any) => {
                  setRadioChecked(value);
                  if (onChange !== undefined) {
                    onChange(e.target.checked, { label, value });
                  }
                }}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export function DarkCheckbox({
  name,
  value,
  onChange,
  label,
  isChecked,
  readOnly = false,
}: DarkCheckboxProps) {
  const [checked, setChecked] = React.useState(false);
  const testChecked = isChecked !== undefined ? isChecked : checked;
  return (
    <label
      className={`flex ${
        readOnly ? "cursor-default" : "cursor-pointer"
      } py-3 items-center`}
      onClick={(e) => {
        if (readOnly) {
          e.preventDefault();
        }
      }}
    >
      <div
        className={`border border-black rounded w-4 h-4 overflow-hidden flex justify-center items-center mr-2 flex-shrink-0 ${
          testChecked ? "bg-black" : ""
        }`}
      >
        <input
          type="checkbox"
          name={name}
          value={value}
          checked={testChecked}
          onChange={(e) => {
            setChecked(e.target.checked);
            onChange(e);
          }}
          className="hidden"
          readOnly={readOnly}
        />
        <svg
          className={`${
            testChecked ? "" : "hidden"
          } w-full h-full text-white pointer-events-none`}
          viewBox="0 0 172 172"
        >
          <g
            fill="none"
            strokeWidth="none"
            strokeMiterlimit="10"
            fontFamily="none"
            fontWeight="none"
            fontSize="none"
            textAnchor="none"
          >
            <path d="M0 172V0h172v172z" />
            <path
              d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
              fill="currentColor"
              strokeWidth="2 "
            />
          </g>
        </svg>
      </div>
      <span className="select-none">{label}</span>
    </label>
  );
}

function DarkRadio({
  name,
  value,
  onChange,
  label,
  readOnly = false,
  checked,
}: DarkRadioProps) {
  return (
    <label
      className={`flex cursor-pointer py-3 items-center`}
      onClick={(e) => {
        if (readOnly) {
          e.preventDefault();
        }
      }}
    >
      <div
        className={`${
          checked ? `border-black` : "border-gray-300"
        } border-2 rounded-full h-5 w-5 overflow-hidden flex justify-center items-center mr-2 flex-shrink-0`}
      >
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={(e) => {
            onChange(e);
          }}
          className="hidden"
          readOnly={readOnly}
        />
        <div
          className={`${
            checked ? "" : "hidden"
          } bg-black rounded-full innerRadio`}
        />
      </div>

      {label && (
        <span className="select-none text-base font-normal">{label}</span>
      )}
    </label>
  );
}
