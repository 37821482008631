import { Route, Switch } from "react-router-dom";

import AgenciesAdmin from "./pages/AgenciesAdmin";
import BadgesAdmin from "./pages/BadgesAdmin";
import Collaborator from "./pages/Collaborator";
import Collaborators from "./pages/Collaborators";
import CreateCollaborator from "./pages/CreateCollaborator";
import DepartmentsAdmin from "./pages/DepartmentsAdmin";
import Home from "./pages/Home";
import JobsAdmin from "./pages/JobsAdmin";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import {
  ModuleChecker,
  PermissionChecker,
  useToken,
} from "./contexts/permissions";
import PrivateRoute from "./components/PrivateRoute";
import React from "react";
import ServicesAdmin from "./pages/ServicesAdmin";
import Statistics from "./pages/Statistics";
import TypeBountiesAdmin from "./pages/TypeBountiesAdmin";
import TypeCalculationMethodAdmin from "./pages/TypeCalculationMethodAdmin";
import TypeReasonsAdmin from "./pages/TypeReasonsAdmin";
import TypeRequestsAdmin from "./pages/TypeRequestsAdmin";
import TypeWeeklyWorkingDurationsAdmin from "./pages/TypeWeeklyWorkingDurationsAdmin";
import TypesAbsencesAdmin from "./pages/TypesAbsencesAdmin";
import UserAdmin from "./pages/UserAdmin";
import ContractStatusAdmin from "./pages/ContractStatusAdmin";
import TypeContractsAdmin from "./pages/TypeContractsAdmin";
import AlertsAdmin from "./pages/AlertsAdmin";
import UserPage from "./pages/User";
import Vehicles from "./pages/Vehicle/Vehicles";
import CreateVehicle from "./pages/Vehicle/CreateVehicle";
import VehiclePage from "./pages/Vehicle";
import NewVehicleAllocationPage from "./pages/Vehicle/NewVehicleAllocation";
import NewVehicleRentalPage from "./pages/Vehicle/NewVehicleRental";
import TypeVehicleAdmin from "./pages/TypeVehicleAdmin";
import TypeActionAdmin from "./pages/TypeActionAdmin";
import ExportsAdmin from "./pages/ExportsAdmin";
import TypeMessageAdmin from "./pages/TypeMessageAdmin";
import SendMessage from "./pages/SendMessage";
import SendRequest from "./pages/SendRequest";
import Catalogue from "./pages/Accreditation/Catalogue";
import CreateAccreditation from "./pages/Accreditation/CreateAccreditation";
import EditAccreditationPage from "./pages/Accreditation/EditAccreditation";
import AffectedAccreditations from "./pages/Accreditation/AffectedAccreditations";
import EditAffectedAccreditationPage from "./pages/Accreditation/EditAffectedAccreditation";
import WorkAccidents from "./pages/Prevention/Workaccidents";
import CreateWorkAccident from "./pages/Prevention/CreateWorkAccident";
import TypeWorkAccidentLocationAdmin from "./pages/TypeWorkAccidentLocationAdmin";
import TypeWorkAccidentAdmin from "./pages/TypeWorkAccidentAdmin";
import TypeWorkAccidentBodyAreaAdmin from "./pages/TypeWorkAccidentBodyAreaAdmin";
import EditWorkAccidentPage from "./pages/Prevention/EditWorkAccident";
import RequalifiedWorkaccidents from "./pages/Prevention/RequalifiedWorkaccidents";
import MessagesPrevention from "./pages/Prevention/MessagesPrevention";
import MessagesPreventionchantiers from "./pages/Prevention/MessagesPreventionchantiers";
import RequestsPrevention from "./pages/Prevention/RequestsPrevention";
import CatalogueFormations from "./pages/Formation/CatalogueFormation";
import CreateFormation from "./pages/Formation/CreateFormation";
import EditFormationPage from "./pages/Formation/EditFormation";
import AffectedFormations from "./pages/Formation/AffectedFormations";
import DashboardAdmin from "./pages/Administration/DashboardAdmin";
import Requests from "./pages/Requests";
import Messages from "./pages/Messages";
import Alerts from "./pages/Alerts";
import SynchronisationErrors from "./pages/SynchronisationErrors";
import TypeContractCategoryAdmin from "./pages/TypeContractCategoryAdmin";
import Process from "./pages/Process";
import TypeProcessOutAdmin from "./pages/TypeProcessOutAdmin";
import StartedProcessInOut from "./pages/StartedProcessInOut";
import StartedProcessInOutSuivi from "./pages/StartedProcessInOutSuivi";
import StartedProcessTasks from "./pages/StartedProcessTasks";
import DomainAdmin from "./pages/DomainAdmin";
import ValidationHolidays from "./pages/ValidationHolidays";
import ValidatorAdminPage from "./pages/Administration/ValidatorAdminPage";
import AbsenceNotifications from "./pages/AbsenceNotifications";
import CreateAbsence from "./pages/CreateAbsence";
import ModuleAdmin from "./pages/ModuleAdmin";
import PermissionAdminPage from "./pages/Administration/PermissionAdmin";
import ApiSilaeForm from "./forms/ApiSilaeForm";
import OrganizationDocuments from "./pages/OrganizationDocuments";
import PlanningAbsences from "./pages/PlanningAbsences";
import CollaboratorsAbsents from "./pages/CollaboratorsAbsents";
import OvertimeImport from "./pages/OvertimeImport";
import RttImport from "./pages/RttImport";
import { Redirect } from "react-router";
import CompaniesAdmin from "./pages/CompaniesAdmin";
import LinksAdmin from "./pages/LinksAdmin";
import MyLinks from "./api/myLinks";
import MessagesDirigeantPrevention from "./pages/Prevention/MessagesDirigeantPrevention";
import LokoaErrorPage from "./pages/LokoaErrorPage";
import LokoaAdmin from "./pages/LokoaAdmin";
import LokoaRequests from "./pages/LokoaRequests";
import CreateEventPage from "./pages/CreateEvent";
import NewOrganizationDocuments from "./pages/NewOrganizationDocuments";
import SkillDomains from "./pages/Skill/SkillDomains";
import Works from "./pages/Skill/Works";
import WorkDetail from "./pages/Skill/WorkDetail";
import SkillEvaluatorPage from "./pages/SkillEvaluatorPage";
import EvaluationPage from "./pages/Skill/EvaluationPage";
import EvaluationHistory from "./pages/Skill/EvaluationHistory";
import LastEvaluations from "./pages/LastEvaluations";
import SkillsMapping from "./pages/Skill/SkillsMapping";
import CampaignTracking from "./pages/Skill/CampaignTracking";
import CreateCampaign from "./pages/Skill/CreateCampaign";

function MainRouter() {
  const isLoggedIn = useToken();

  return (
    <Switch>
      <Route path="/login" exact>
        {isLoggedIn ? <Redirect to={"/"} /> : <Login />}
      </Route>
      <PrivateRoute path="/rh/creation-collaborateur">
        <PermissionChecker permissions={["kdix.actions.collaborator.create"]}>
          <CreateCollaborator />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/collaborateurs/:id">
        <PermissionChecker
          permissions={[
            "kdix.actions.collaborator.view",
            "kdix.actions.collaborator.view.agency",
            "kdix.actions.collaborator.view.department",
            "kdix.actions.collaborator.view.service",
            "kdix.actions.collaborator.view.own",
          ]}
        >
          <Collaborator />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/statistiques" exact>
        <PermissionChecker permissions={["kdix.actions.statistics.view"]}>
          <Statistics />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/collaborateurs" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.collaborator.view",
            "kdix.actions.collaborator.view.agency",
            "kdix.actions.collaborator.view.department",
            "kdix.actions.collaborator.view.service",
            "kdix.actions.collaborator.view.own",
          ]}
        >
          <Collaborators />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/mot-dirigeant">
        <PermissionChecker
          permissions={[
            "kdix.actions.home_message.create",
            "kdix.actions.home_message.history",
          ]}
        >
          <DashboardAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/societes" exact>
        <PermissionChecker permissions={["kdix.actions.agency.edit"]}>
          <CompaniesAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/agences" exact>
        <PermissionChecker permissions={["kdix.actions.agency.edit"]}>
          <AgenciesAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/badges" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <BadgesAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/code-service" exact>
        <PermissionChecker permissions={["kdix.actions.agency.edit"]}>
          <ServicesAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/emplois" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <JobsAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/liens" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <LinksAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/departements" exact>
        <PermissionChecker permissions={["kdix.actions.agency.edit"]}>
          <DepartmentsAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/exports" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.collaborator.export",
            "kdix.actions.collaborator.export.agency",
            "kdix.actions.collaborator.export.department",
            "kdix.actions.collaborator.export.service",
            "kdix.actions.collaborator.export.own",
            "kdix.actions.mood.view",
            "kdix.actions.mood.view.agency",
            "kdix.actions.mood.view.department",
            "kdix.actions.mood.view.service",
            "kdix.actions.vehicle.edit",
            "kdix.actions.vehicle.edit.agency",
            "kdix.actions.vehicle.edit.department",
            "kdix.actions.vehicle.edit.service",
            "kdix.actions.vehicle.edit.own",
          ]}
        >
          <ExportsAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/modules" exact>
        <PermissionChecker>
          <ModuleAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/droits" exact>
        <PermissionChecker permissions={["kdix.actions.user.manage"]}>
          <PermissionAdminPage />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/lokoa" exact>
        <PermissionChecker permissions={["kdix.actions.user.manage"]}>
          <LokoaAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/profil">
        <PermissionChecker permissions={["kdix.actions.user.manage"]}>
          <UserAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/types-absences" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <TypesAbsencesAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/valideurs" exact>
        <PermissionChecker permissions={["kdix.actions.user.manage", "kdix.actions.validators.view"]}>
          <ValidatorAdminPage />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/pre-valideurs" exact>
        <PermissionChecker permissions={["kdix.actions.user.manage", "kdix.actions.validators.view"]}>
          <ValidatorAdminPage preValidatorPage={true} />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/types-primes" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <TypeBountiesAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/types-duree-hebdomadaire" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <TypeWeeklyWorkingDurationsAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/types-raisons" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <TypeReasonsAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/types-methodes-calcul" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <TypeCalculationMethodAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/types-demandes" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <TypeRequestsAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/types-contrats" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <TypeContractsAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/types-contrat-categorie" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <TypeContractCategoryAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/types-vehicule" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <TypeVehicleAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/types-message" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <TypeMessageAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/types-action" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <TypeActionAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/types-lieu-accident" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <TypeWorkAccidentLocationAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/types-localisation-corporelle" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <TypeWorkAccidentBodyAreaAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/types-accident" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <TypeWorkAccidentAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/statuts-contrat" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <ContractStatusAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/alertes" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <AlertsAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/types-process" exact>
        <PermissionChecker permissions={["kdix.actions.alert_badge.edit"]}>
          <TypeProcessOutAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.collaborator.view",
            "kdix.actions.collaborator.view.agency",
            "kdix.actions.collaborator.view.department",
            "kdix.actions.collaborator.view.service",
            "kdix.actions.collaborator.view.own",
          ]}
        >
          <Home />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/lokoa-error" exact>
        <PermissionChecker permissions={["kdix.actions.lokoa.view"]}>
          <LokoaErrorPage />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/deploiement/domains" exact>
        <PermissionChecker permissions={[]}>
          <DomainAdmin />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/parametres/deploiement/silae" exact>
        <PermissionChecker>
          <ApiSilaeForm />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/creation-event" exact>
        <CreateEventPage />
      </PrivateRoute>
      <PrivateRoute path="/changement-mdp" exact>
        <UserPage />
      </PrivateRoute>
      <PrivateRoute path="/envoyer-message" exact>
        <SendMessage />
      </PrivateRoute>
      <PrivateRoute path="/envoyer-demande" exact>
        <SendRequest />
      </PrivateRoute>
      <PrivateRoute path="/rh/vehicules" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.vehicle.view",
            "kdix.actions.vehicle.view.agency",
            "kdix.actions.vehicle.view.department",
            "kdix.actions.vehicle.view.service",
            "kdix.actions.vehicle.view.own",
          ]}
        >
          <Vehicles />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/creation-vehicule">
        <PermissionChecker
          permissions={[
            "kdix.actions.vehicle.edit",
            "kdix.actions.vehicle.edit.agency",
            "kdix.actions.vehicle.edit.department",
            "kdix.actions.vehicle.edit.service",
            "kdix.actions.vehicle.edit.own",
          ]}
        >
          <CreateVehicle />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/vehicules/:id/reattribution-vehicule">
        <PermissionChecker
          permissions={[
            "kdix.actions.vehicle.edit",
            "kdix.actions.vehicle.edit.agency",
            "kdix.actions.vehicle.edit.department",
            "kdix.actions.vehicle.edit.service",
            "kdix.actions.vehicle.edit.own",
          ]}
        >
          <NewVehicleAllocationPage />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/vehicules/:id/rental">
        <PermissionChecker
          permissions={[
            "kdix.actions.vehicle.edit",
            "kdix.actions.vehicle.edit.agency",
            "kdix.actions.vehicle.edit.department",
            "kdix.actions.vehicle.edit.service",
            "kdix.actions.vehicle.edit.own",
          ]}
        >
          <NewVehicleRentalPage />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/vehicules/:id">
        <PermissionChecker
          permissions={[
            "kdix.actions.vehicle.view",
            "kdix.actions.vehicle.view.agency",
            "kdix.actions.vehicle.view.department",
            "kdix.actions.vehicle.view.service",
            "kdix.actions.vehicle.view.own",
            "kdix.actions.collaborator.view",
            "kdix.actions.collaborator.view.agency",
            "kdix.actions.collaborator.view.department",
            "kdix.actions.collaborator.view.service",
            "kdix.actions.collaborator.view.own",
          ]}
        >
          <VehiclePage />
        </PermissionChecker>
      </PrivateRoute>
      CreateWorkAccidentForm
      <PrivateRoute path="/rh/habilitations/catalogue" exact>
        <ModuleChecker module="accreditation">
          <Catalogue />
        </ModuleChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/habilitations/habilites" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.accreditation.manage",
            "kdix.actions.accreditation.manage.agency",
            "kdix.actions.accreditation.manage.department",
            "kdix.actions.accreditation.manage.service",
            "kdix.actions.accreditation.manage.own",
          ]}
        >
          <AffectedAccreditations />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/habilitations/creation-habilitation">
        <PermissionChecker
          permissions={[
            "kdix.actions.accreditation.manage",
            "kdix.actions.accreditation.manage.agency",
            "kdix.actions.accreditation.manage.department",
            "kdix.actions.accreditation.manage.service",
            "kdix.actions.accreditation.manage.own",
          ]}
        >
          <CreateAccreditation />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/habilitations/habilite/:id">
        <PermissionChecker
          permissions={[
            "kdix.actions.accreditation.manage",
            "kdix.actions.accreditation.manage.agency",
            "kdix.actions.accreditation.manage.department",
            "kdix.actions.accreditation.manage.service",
            "kdix.actions.accreditation.manage.own",
          ]}
        >
          <EditAffectedAccreditationPage />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/habilitations/habilitation/:id">
        <PermissionChecker
          permissions={[
            "kdix.actions.accreditation.manage",
            "kdix.actions.accreditation.manage.agency",
            "kdix.actions.accreditation.manage.department",
            "kdix.actions.accreditation.manage.service",
            "kdix.actions.accreditation.manage.own",
          ]}
        >
          <EditAccreditationPage />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/prevention/accidents-requalifies" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.work_accident.view",
            "kdix.actions.work_accident.view.agency",
            "kdix.actions.work_accident.view.department",
            "kdix.actions.work_accident.view.service",
            "kdix.actions.work_accident.view.own",
          ]}
        >
          <RequalifiedWorkaccidents />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/prevention/messages-prevention" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.request_prevention.view",
            "kdix.actions.request_prevention.view.agency",
            "kdix.actions.request_prevention.view.department",
            "kdix.actions.request_prevention.view.service",
            "kdix.actions.request_prevention.view.own",
          ]}
        >
          <MessagesPrevention />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/prevention/messages-preventionchantiers" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.request_prevention.view",
            "kdix.actions.request_prevention.view.agency",
            "kdix.actions.request_prevention.view.department",
            "kdix.actions.request_prevention.view.service",
            "kdix.actions.request_prevention.view.own",
          ]}
        >
          <MessagesPreventionchantiers />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/prevention/demandes-prevention" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.request_prevention.view",
            "kdix.actions.request_prevention.view.agency",
            "kdix.actions.request_prevention.view.department",
            "kdix.actions.request_prevention.view.service",
            "kdix.actions.request_prevention.view.own",
          ]}
        >
          <RequestsPrevention />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/prevention/messages-dirigeant" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.message.view",
            "kdix.actions.message.view.agency",
            "kdix.actions.message.view.department",
            "kdix.actions.message.view.service",
            "kdix.actions.message.view.own",
          ]}
        >
          <MessagesDirigeantPrevention />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/prevention/accidents-travail" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.work_accident.view",
            "kdix.actions.work_accident.view.agency",
            "kdix.actions.work_accident.view.department",
            "kdix.actions.work_accident.view.service",
            "kdix.actions.work_accident.view.own",
          ]}
        >
          <WorkAccidents />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/prevention/creation-accident">
        <PermissionChecker permissions={["kdix.actions.work_accident.create"]}>
          <CreateWorkAccident />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/prevention/accident/:id">
        <PermissionChecker
          permissions={[
            "kdix.actions.work_accident.view",
            "kdix.actions.work_accident.view.agency",
            "kdix.actions.work_accident.view.department",
            "kdix.actions.work_accident.view.service",
            "kdix.actions.work_accident.view.own",
          ]}
        >
          <EditWorkAccidentPage />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/formations/catalogue" exact>
        <ModuleChecker module="formation">
          <CatalogueFormations />
        </ModuleChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/formations/formes" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.formation.view",
          ]}
        >
          <AffectedFormations />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/formations/creation-formation">
        <PermissionChecker
          permissions={[
            "kdix.actions.formation.edit",
            "kdix.actions.formation.edit.agency",
            "kdix.actions.formation.edit.department",
            "kdix.actions.formation.edit.service",
            "kdix.actions.formation.edit.own",
          ]}
        >
          <CreateFormation />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/formations/formation/:id">
        <PermissionChecker
          permissions={[
            "kdix.actions.formation.edit",
            "kdix.actions.formation.edit.agency",
            "kdix.actions.formation.edit.department",
            "kdix.actions.formation.edit.service",
            "kdix.actions.formation.edit.own",
          ]}
        >
          <EditFormationPage />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/evaluation/:id" exact>
        <EvaluationPage />
      </PrivateRoute>
      <PrivateRoute path="/rapport_evaluation/:id" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.skill_evaluation.view_evaluations",
            "kdix.actions.skill_evaluation.view_evaluations.agency",
            "kdix.actions.skill_evaluation.view_evaluations.department",
            "kdix.actions.skill_evaluation.view_evaluations.service",
            "kdix.actions.skill_evaluation.view_evaluations.own",
          ]}
        >
          <EvaluationHistory />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/last_rapport_evaluation/:id" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.skill_evaluation.view_evaluations",
            "kdix.actions.skill_evaluation.view_evaluations.agency",
            "kdix.actions.skill_evaluation.view_evaluations.department",
            "kdix.actions.skill_evaluation.view_evaluations.service",
            "kdix.actions.skill_evaluation.view_evaluations.own",
          ]}
        >
          <EvaluationHistory pageBack="/evaluations" />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/competences" exact>
        <PermissionChecker
          permissions={["kdix.actions.skill_evaluation.edit_create"]}
        >
          <SkillDomains />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/competences/domaine/:id" exact>
        <PermissionChecker
          permissions={["kdix.actions.skill_evaluation.edit_create"]}
        >
          <SkillDomains />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/fiches_poste" exact>
        <PermissionChecker
          permissions={["kdix.actions.skill_evaluation.edit_create"]}
        >
          <Works />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/fiche_poste/:id(\d+)" exact>
        <PermissionChecker
          permissions={["kdix.actions.skill_evaluation.edit_create"]}
        >
          <WorkDetail />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/fiche_poste/creer" exact>
        <PermissionChecker
          permissions={["kdix.actions.skill_evaluation.edit_create"]}
        >
          <WorkDetail />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/competence/evaluateurs" exact>
        <PermissionChecker
          permissions={["kdix.actions.skill_evaluation.edit_create"]}
        >
          <SkillEvaluatorPage />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/competences/cartographies" exact>
        <PermissionChecker
          permissions={["kdix.actions.skill_evaluation.edit_create"]}
        >
          <SkillsMapping />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/competences/suivi_campagnes" exact>
        <PermissionChecker
          permissions={["kdix.actions.skill_evaluation.edit_campaign",
          "kdix.actions.skill_evaluation.viewall_campaign"]}
        >
          <CampaignTracking />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/rh/competences/creation-campaign">
        <PermissionChecker
          permissions={["kdix.actions.skill_evaluation.edit_campaign"]}
        >
          <CreateCampaign />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/evaluations">
        <LastEvaluations />
      </PrivateRoute>
      <PrivateRoute path="/alertes">
        <Alerts />
      </PrivateRoute>
      <PrivateRoute path="/demandes">
        <ModuleChecker module="requests">
          <Requests />
        </ModuleChecker>
      </PrivateRoute>
      <PrivateRoute path="/demandes-chantier">
        <PermissionChecker permissions={["kdix.actions.lokoa_projects.view"]}>
          <LokoaRequests />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/messages/validation">
        <PermissionChecker permissions={["kdix.actions.message.moderate"]}>
          <Messages toValidate={true} />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/messages" exact>
        <Messages />
      </PrivateRoute>
      <PrivateRoute path="/new-documents" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.documents_link.view",
            "kdix.actions.documents_link.view_all",
          ]}
        >
          <NewOrganizationDocuments />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/synchronisation-errors" exact>
        <PermissionChecker
          permissions={["kdix.actions.synchronisation_error.view"]}
        >
          <SynchronisationErrors />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/processus/entree" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.process_in_out.view",
            "kdix.actions.process_in_out.view.agency",
            "kdix.actions.process_in_out.view.department",
            "kdix.actions.process_in_out.view.service",
            "kdix.actions.process_in_out.view.own",
          ]}
        >
          <Process isOut={false} />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/processus/sortie" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.process_in_out.view",
            "kdix.actions.process_in_out.view.agency",
            "kdix.actions.process_in_out.view.department",
            "kdix.actions.process_in_out.view.service",
            "kdix.actions.process_in_out.view.own",
          ]}
        >
          <Process isOut={true} />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/processus/liste/:id?" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.process_in_out.view",
            "kdix.actions.process_in_out.view.agency",
            "kdix.actions.process_in_out.view.department",
            "kdix.actions.process_in_out.view.service",
            "kdix.actions.process_in_out.view.own",
          ]}
        >
          <StartedProcessInOutSuivi />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/processus/:id" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.process_in_out.view",
            "kdix.actions.process_in_out.view.agency",
            "kdix.actions.process_in_out.view.department",
            "kdix.actions.process_in_out.view.service",
            "kdix.actions.process_in_out.view.own",
          ]}
        >
          <Process />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/processus/entree/new/:id" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.process_in_out.edit",
            "kdix.actions.process_in_out.edit.agency",
            "kdix.actions.process_in_out.edit.department",
            "kdix.actions.process_in_out.edit.service",
            "kdix.actions.process_in_out.edit.own",
          ]}
        >
          <StartedProcessInOut isOut={false} />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/processus/sortie/new/:id" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.process_in_out.edit",
            "kdix.actions.process_in_out.edit.agency",
            "kdix.actions.process_in_out.edit.department",
            "kdix.actions.process_in_out.edit.service",
            "kdix.actions.process_in_out.edit.own",
          ]}
        >
          <StartedProcessInOut isOut={true} />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/taches" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.process_in_out.view",
            "kdix.actions.process_in_out.view.agency",
            "kdix.actions.process_in_out.view.department",
            "kdix.actions.process_in_out.view.service",
            "kdix.actions.process_in_out.view.own",
          ]}
        >
          <StartedProcessTasks />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/validation-absences" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.absence.edit",
            "kdix.actions.absence.edit.agency",
            "kdix.actions.absence.edit.department",
            "kdix.actions.absence.edit.service",
            "kdix.actions.absence.edit.own",
          ]}
        >
          <ValidationHolidays />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/planning-absences" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.absence.view",
            "kdix.actions.absence.view.agency",
            "kdix.actions.absence.view.department",
            "kdix.actions.absence.view.service",
          ]}
        >
          <PlanningAbsences />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/collaborators-absents" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.absence.view",
            "kdix.actions.absence.view.agency",
            "kdix.actions.absence.view.department",
            "kdix.actions.absence.view.service",
          ]}
        >
          <CollaboratorsAbsents />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/absences" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.absence.view",
            "kdix.actions.absence.view.agency",
            "kdix.actions.absence.view.department",
            "kdix.actions.absence.view.service",
            "kdix.actions.absence.view.own",
          ]}
        >
          <AbsenceNotifications />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/creer-absence" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.absence.edit",
            "kdix.actions.absence.edit.agency",
            "kdix.actions.absence.edit.department",
            "kdix.actions.absence.edit.service",
            "kdix.actions.absence.edit.own",
          ]}
        >
          <CreateAbsence />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/documents-entreprise" exact>
        <PermissionChecker
          permissions={[
            "kdix.actions.documents_link.view",
            "kdix.actions.documents_link.view_all",
          ]}
        >
          <OrganizationDocuments />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/liens" exact>
        <PermissionChecker permissions={["kdix.actions.my_links.view"]}>
          <MyLinks />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/importer-heures-supplementaires" exact>
        <PermissionChecker permissions={["kdix.actions.overtime.manage"]}>
          <OvertimeImport />
        </PermissionChecker>
      </PrivateRoute>
      <PrivateRoute path="/importer-rtt" exact>
        <PermissionChecker permissions={["kdix.actions.rtt.manage"]}>
          <RttImport />
        </PermissionChecker>
      </PrivateRoute>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}

export default MainRouter;
