import React from "react";

export default function Export({ label, children }) {
  return (
    <div className="bg-white shadow md:w-2/3 w-full">
      <div className="bg-gray-75 p-4 font-bold text-2xl">
        <div>{label}</div>
      </div>
      <div>{children}</div>
    </div>
  );
}
