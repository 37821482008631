import React, { useContext, useEffect, useState } from "react";
import { flatMap, get, map, pull, size } from "lodash-es";
import { useDebounce, useMap, useToggle } from "react-use";
import Alert from "../components/Alert";
import Avatar from "../components/Avatar";
import CheckboxGroup from "../components/CheckboxGroup";
import CollaboratorGeneralInfosForm from "../forms/CollaboratorGeneralInfosForm";
import ExpansionPanel from "../components/ExpansionPanel";
import { ReactComponent as FilterIcon } from "../svgs/filters.svg";
import { ReactComponent as AbsenceIcon } from "../svgs/Absence.svg";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import Modal from "../components/Modal";
import {
  PermissionChecker,
  useAgencies,
  useCollaboratorId,
  useDepartments,
  usePermissionsChecker,
  useServiceId,
} from "../contexts/permissions";
import { ReactComponent as PlusIcon } from "../svgs/plus.svg";
import Spinner from "../components/Spinner";
import { useDesktop } from "../hooks/useDesktop";
import { useQueryCollaborators } from "../api/collaborator";
import { useQueryAgencies } from "../api/agencies";
import { useQueryTypeContracts } from "../api/typeContracts";
import { useQueryServices } from "../api/services";
import { ReactComponent as BadgeIcon } from "../svgs/badge.svg";
import InfoTooltip from "../components/InfoTooltip";
import { isMissingToday } from "../utils/absence";
import { getCurrentsBadges } from "../utils/badges";
import { HeaderBar } from "../components/HeaderBar";
import CustomLink from "../components/CustomLink";
import { useQueryDepartments } from "../api/department";
import CollaboratorFiltersProvider, {
  CollaboratorFiltersContext,
} from "../contexts/collaboratorFilters";
import { getListPermissionsClickable } from "../utils/collaborators";
import { ReactComponent as EmailIcon } from "../svgs/email.svg";
import { useQueryCompanies } from "../api/companies";
import { useQueryAbsences } from "../api/absence";
import Button from "../components/Button";

function InlineBLockContent({ children, className = "", style = {} }) {
  return (
    <div className={`px-4 flex items-center ${className}`} style={style}>
      {children}
    </div>
  );
}

function InlineBLockContentSortable({
  children,
  setOrderFilter,
  colName,
  className = "",
  style = {},
}) {
  return (
    <div
      className={`px-4 flex items-center inl ine-col-sortable ${className}`}
      style={style}
      onClick={() => {
        setOrderFilter((orderFilter) => {
          const order = orderFilter[`order[${colName}]`];
          return {
            [`order[${colName}]`]: order === "asc" ? "desc" : "asc",
          };
        });
      }}
    >
      {children}
    </div>
  );
}

function Badges({ badges }) {
  return (
    <div className={"mr-2"}>
      {map(badges, (badge, key) => (
        <div
          key={key}
          style={{ backgroundColor: badge?.type?.color }}
          className="h-12 w-12 rounded-full inline-flex items-center justify-center mr-1"
        >
          <BadgeIcon className="fill-current text-white w-6 h-6" />
        </div>
      ))}
    </div>
  );
}

function CollaboratorMobileCard({
  collaborator,
  permissionsClickable,
  collaboratorId,
}) {
  const clickable =
    usePermissionsChecker({
      permissions: permissionsClickable,
      service: collaborator.service?.id ?? null,
      departments: collaborator.departments
        ? map(collaborator.departments, "@id")
        : null,
      agencies: collaborator.agencies
        ? map(collaborator.agencies, "@id")
        : null,
    }) || +collaborator.id === +collaboratorId;

  const { currents: currentsBadge } = getCurrentsBadges(
    get(collaborator, "badges", []),
  );

  return (
    <ExpansionPanel
      key={collaborator.id}
      title={`${collaborator.firstname} ${collaborator.lastname}`}
      subtitle={get(collaborator, "currentContract.job.label")}
      image={get(collaborator, "avatar.contentUrl")}
      buttons={<Badges badges={currentsBadge} />}
    >
      <CollaboratorGeneralInfosForm collaborator={collaborator} readOnly />
      {clickable && (
        <Link
          className="btn mt-6 w-full block text-center"
          to={`/rh/collaborateurs/${collaborator.id}`}
        >
          Voir le profil
        </Link>
      )}
    </ExpansionPanel>
  );
}

export function CollaboratorsList({ query, filters }) {
  const isDesktop = useDesktop();
  const [orderFilter, setOrderFilter] = useState({ "order[lastname]": "asc" });
  const { data, fetchMore, canFetchMore } = useQueryCollaborators({
    ...orderFilter,
    orSearch_fullname: query,
    ...filters,
  });

  const permissionsClickable = getListPermissionsClickable();

  const collaboratorId = useCollaboratorId();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const { data: listAbsences } = useQueryAbsences({
    "startDate[before]": today,
    "endDate[after]": today,
    status: "validated",
    "type.noPlanning": false,
  });

  if (size(data) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="aucun collaborateur" />
      </div>
    );
  }
  const gridTemplateColumn = "5rem 16% 14% 12% 16% 16% auto 6rem";
  const nbCollaborators = data[0]?.["hydra:totalItems"];
  const collaborators = flatMap(data, (page) => page["hydra:member"]);
  return (
    <div className="relative">
      {isDesktop ? (
        <div>
          <div className="absolute -top-14 py-4 right-0">
            {nbCollaborators} Collaborateurs
          </div>
          <div
            className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 sticky top-tab-header always-front`}
            style={{
              gridTemplateColumns: gridTemplateColumn,
            }}
          >
            <InlineBLockContent />
            <InlineBLockContentSortable
              setOrderFilter={setOrderFilter}
              colName={"lastname"}
            >
              Nom prénom
            </InlineBLockContentSortable>
            <InlineBLockContent>Tél/Mail professionnel</InlineBLockContent>
            <InlineBLockContentSortable
              setOrderFilter={setOrderFilter}
              colName={"agencies.label"}
            >
              Société / Site
            </InlineBLockContentSortable>
            <InlineBLockContentSortable
              setOrderFilter={setOrderFilter}
              colName={"departments.label"}
            >
              Département
            </InlineBLockContentSortable>
            <InlineBLockContentSortable
              setOrderFilter={setOrderFilter}
              colName={"service.code"}
            >
              Service
            </InlineBLockContentSortable>
            <InlineBLockContentSortable
              setOrderFilter={setOrderFilter}
              colName={"currentContract.job.label"}
            >
              Emploi
            </InlineBLockContentSortable>
            <InlineBLockContent>Badge</InlineBLockContent>
          </div>
        </div>
      ) : (
        <div className="absolute -top-14 py-4 right-0">{nbCollaborators}</div>
      )}
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {isDesktop
          ? map(collaborators, (node) => (
              <SingleCollaborator
                key={node.id}
                node={node}
                gridTemplateColumn={gridTemplateColumn}
                permissionsClickable={permissionsClickable}
                collaboratorId={collaboratorId}
                listAbsences={listAbsences}
              />
            ))
          : map(collaborators, (node) => (
              <CollaboratorMobileCard
                key={node.id}
                collaborator={node}
                collaboratorId={collaboratorId}
                permissionsClickable={permissionsClickable}
              />
            ))}
      </InfiniteScroll>
    </div>
  );
}

function SingleCollaborator({
  node,
  gridTemplateColumn,
  permissionsClickable,
  collaboratorId,
  listAbsences = null,
}) {
  const { currents: currentsBadge } = getCurrentsBadges(node.badges);

  const isMissing = isMissingToday(listAbsences, node.id);

  const clickable =
    usePermissionsChecker({
      permissions: permissionsClickable,
      service: node.service?.id ?? null,
      departments: node.departments ? map(node.departments, "@id") : null,
      agencies: node.agencies ? map(node.agencies, "@id") : null,
      companies: node.companies ? map(node.companies, "@id") : null,
    }) || +node.id === +collaboratorId;

  return (
    <CustomLink
      className={`grid divide-x divide-gray-100  hover:bg-purple-50 shadow mb-2 py-2 ${
        isMissing ? "bg-blue-50" : "bg-white"
      }`}
      disabled={!clickable}
      to={`/rh/collaborateurs/${node.id}`}
      style={{
        gridTemplateColumns: gridTemplateColumn,
      }}
    >
      <InlineBLockContent className="">
        <Avatar avatar={node.avatar?.contentUrl} />
      </InlineBLockContent>
      <InlineBLockContent className="">
        {isMissing && <AbsenceIcon className={"mr-1"} />}
        {node.lastname} {node.firstname}
      </InlineBLockContent>
      <InlineBLockContent className="flex-col">
        <div className="">{node.telephoneNumber}</div>
        <div className="">
          {node.email ? (
            <span
              onClick={(e) => {
                e.stopPropagation();
                window.open(`mailto:${node.email}`);
              }}
            >
              <InfoTooltip message={node.email} left onHover>
                <EmailIcon />
              </InfoTooltip>
            </span>
          ) : null}
        </div>
      </InlineBLockContent>
      <InlineBLockContent className="">
        <div className={"flex flex-col"}>
          {map(node.companies, (company, key) => (
            <div key={key} className="mr-2">
              {company.label.toUpperCase()}
            </div>
          ))}
          {map(node.agencies, (agency, key) => (
            <div key={key} className="mr-2">
              {agency.label.toLowerCase()}
            </div>
          ))}
        </div>
      </InlineBLockContent>
      <InlineBLockContent className="">
        <div className={"flex flex-col"}>
          {map(node.departments, (department, key) => (
            <div key={key} className="mr-2">
              {department.label.toLowerCase()}
            </div>
          ))}
        </div>
      </InlineBLockContent>
      <InlineBLockContent style={{ overflowWrap: "anywhere" }}>
        {get(node, "service.code")}
      </InlineBLockContent>

      <InlineBLockContent className="">
        {get(node, "currentContract.job.label")}
      </InlineBLockContent>
      <InlineBLockContent className="justify-center">
        <div className={"flex flex-wrap justify-center"}>
          {map(currentsBadge, (badge, key) => {
            return (
              <InfoTooltip
                key={key}
                message={get(badge, "type.title")}
                onHover={true}
              >
                <div
                  style={{ backgroundColor: badge?.type?.color }}
                  className="h-12 w-12 rounded-full inline-flex items-center justify-center mr-1 mb-1"
                >
                  <BadgeIcon className="fill-current text-white w-6 h-6" />
                </div>
              </InfoTooltip>
            );
          })}
        </div>
      </InlineBLockContent>
    </CustomLink>
  );
}

function Collaborators() {
  const { setCollaboratorsFilters, collaboratorsFilters } = useContext(
    CollaboratorFiltersContext,
  );
  const [
    filters,
    { set: setFilter, remove: removeFilter, reset: resetFilters },
  ] = useMap(collaboratorsFilters);
  const { data: companies } = useQueryCompanies();
  const { data: agencies } = useQueryAgencies();
  const { data: departments } = useQueryDepartments();
  const { data: typeContracts } = useQueryTypeContracts();
  const { data: services } = useQueryServices();
  const [FiltersOpen, toggleFilters] = useToggle(false);
  const [query, setQuery] = useState("");
  const [showAll, toggleShowAll] = useToggle(true);
  const baseSpecificFilter = {
    agencies: false,
    departments: false,
    service: false,
  };
  const [specificFilter, setSpecificFilter] = useState(baseSpecificFilter);
  const [debouncedQuery, setDebouncedQuery] = useState("");
  useEffect(() => {
    setCollaboratorsFilters(JSON.stringify(filters));
  }, [setCollaboratorsFilters, filters]);

  const userAgencies = useAgencies();
  const userDepartments = useDepartments();
  const userService = ["/api/services/" + useServiceId()];

  const isDesktop = useDesktop();

  const filtersList = [
    {
      label: "Collaborateur",
      key: "active",
      options: [
        {
          label: "Anciens collaborateurs",
          value: 0,
          id: 0,
        },
        {
          label: "Collaborateurs actifs",
          value: 1,
          id: 1,
        },
      ],
    },
    {
      label: "Société",
      key: "companies",
      options: map(companies, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
    {
      label: "Site",
      key: "agencies",
      options: map(agencies, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
    {
      label: "Départements",
      key: "departments",
      options: map(departments, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
    {
      label: "Type de contrat",
      key: "currentContract.type",
      options: map(typeContracts, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
    {
      label: "Service",
      key: "service",
      options: map(services, (node) => ({
        label: node.code,
        value: node["@id"],
        id: node["@id"],
      })),
    },
  ];

  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query],
  );

  React.useEffect(() => {
    var _paq = (window._paq = window._paq || []);
    _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);

    var u = "//matomo.kdix.pockost.com/";
    _paq.push(["setTrackerUrl", u + "matomo.php"]);
    _paq.push(["setSiteId", "1"]);
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = u + "matomo.js";
    s.parentNode.insertBefore(g, s);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Collaborateurs</title>
      </Helmet>
      <HeaderBar
        sticky={true}
        smaller={true}
        title={<>{isDesktop ? "Annuaire des collaborateurs" : ""}</>}
        buttons={
          <PermissionChecker
            permissions={["kdix.actions.collaborator.create"]}
            notAllowed={null}
          >
            <Link
              to="/rh/creation-collaborateur"
              className="absolute bg-green-600 hover:bg-green-800 text-white rounded-full w-12 h-12 -mb-5 mr-5 bottom-0 right-0 flex justify-center items-center focus:outline-none"
            >
              <PlusIcon className="w-4 h-4" />
            </Link>
          </PermissionChecker>
        }
      >
        <input
          type="text"
          name="query"
          value={query}
          className="mb-0 appearance-none bg-transparent border-b border-white w-full py-2  leading-tight focus:outline-none focus:border-red"
          placeholder="Rechercher un collaborateur"
          onChange={(e) => setQuery(e.target.value)}
        />
      </HeaderBar>

      <div>
        <div className="px-8 mt-8 mb-4">
          {showAll ? (
            <button
              className="flex items-center"
              onClick={() => toggleFilters(true)}
            >
              <FilterIcon />
              <span className="ml-4">Filtres</span>
            </button>
          ) : null}
          <Button
            className={`p-1 px-2 btn-export ${
              showAll ? "btn--outline" : "btn--outline--reversed"
            } mx-1 my-1`}
            onClick={() => {
              setSpecificFilter(baseSpecificFilter);
              resetFilters();
              toggleShowAll(true);
            }}
          >
            Tous
          </Button>
          <Button
            className={`p-1 px-2 btn-export ${
              specificFilter.agencies
                ? "btn--outline"
                : "btn--outline--reversed"
            } mx-1 my-1`}
            onClick={() => {
              toggleShowAll(false);
              setSpecificFilter({ ...baseSpecificFilter, agencies: true });
              resetFilters();
              setFilter("agencies", userAgencies);
            }}
          >
            {isDesktop ? "Mon site" : "site"}
          </Button>
          <Button
            className={`p-1 px-2 btn-export ${
              specificFilter.departments
                ? "btn--outline"
                : "btn--outline--reversed"
            } mx-1 my-1`}
            onClick={() => {
              toggleShowAll(false);
              setSpecificFilter({ ...baseSpecificFilter, departments: true });
              resetFilters();
              setFilter("departments", userDepartments);
            }}
          >
            {isDesktop ? "Mon département" : "dépt"}
          </Button>
          <Button
            className={`p-1 px-2 btn-export ${
              specificFilter.service ? "btn--outline" : "btn--outline--reversed"
            } mx-1 my-1`}
            onClick={() => {
              toggleShowAll(false);
              setSpecificFilter({ ...baseSpecificFilter, service: true });
              resetFilters();
              setFilter("service", userService);
            }}
          >
            {isDesktop ? "Mon service" : "service"}
          </Button>
          <Modal
            title="Filtres"
            handleClose={(e) => {
              e.stopPropagation();
              toggleFilters(false);
            }}
            isOpen={FiltersOpen}
            onRequestClose={() => toggleFilters(false)}
            className="outline-none w-full max-w-full lg:max-w-xl max-h-full overflow-visible overflow-y-auto"
          >
            <div className="-mx-4 px-5">
              {map(filtersList, ({ key, label, options }) => {
                return (
                  <CheckboxGroup
                    key={key}
                    label={label}
                    options={options}
                    className="py-4 odd:bg-purple-50 -mx-4 px-5"
                    selected={get(filters, key, [])}
                    onAdd={(option) => {
                      setFilter(key, [...get(filters, key, []), option]);
                    }}
                    onRemove={(option) => {
                      const newFilters = pull(get(filters, key, []), option);
                      if (size(newFilters) === 0) {
                        removeFilter(key);
                        return;
                      }
                      setFilter(key, newFilters);
                    }}
                  />
                );
              })}
            </div>
            <div>
              <div
                className="mt-3 underline cursor-pointer"
                onClick={() => {
                  resetFilters();
                }}
              >
                Supprimer les filtres
              </div>
            </div>

            <button
              className="btn mt-5 w-full"
              type="button"
              onClick={() => toggleFilters(false)}
            >
              Appliquer les filtres
            </button>
          </Modal>
        </div>
        <div className="px-8 mb-48">
          <React.Suspense fallback={<Spinner />}>
            <CollaboratorsList query={debouncedQuery} filters={filters} />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

export default function CollaboratorsPage() {
  return (
    <CollaboratorFiltersProvider>
      <Collaborators />
    </CollaboratorFiltersProvider>
  );
}
